<template>
  <div class="w-full rounded-md ">
    <div class="shadow-md rounded-md bg-white mr-3 pb-4 pt-4">
      <label class="ml-5 text-base block mb-4"> Transaction in Platform from {{  this.labels_date}}</label>
      <apexchart type="pie" height="335" :options="chartOptionsSales1" :series="series"></apexchart>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import API from "../../plugins/http";
export default {
  name: "LineChart",
  data() {
    return {
      series: [],
      chartOptionsSales1: {},
      data: [],
      datapaid: [],
      labels_date:'',
      dataunpaid: []
    };
  },
  mounted() {
    this.actionGetSalesByPlatForm();
  },
  methods: {
    actionGetSalesByPlatForm() {
      let year = moment().format("YYYY-MM-DD");
      this.labels_date = moment().format("MMMM YYYY");
      API
        .get("chart-dashboard/platform?year=" + year)
        .then(response => {
          let data = response.data.data;
          this.data = data.map(c => {
            return c.count;
          });
          let labels = data.map(l => {
            return l.name;
          });
          this.labels_data = labels
          this.series = this.data;
          this.chartOptionsSales1 = {
            title: {
             // text: "Popular Transaction in Platform from " + moment(year).format("MMMM YYYY"),
              align: "left",
              offsetX: 0,
              offsetY: 0,
            },
            colors: ["#1C64F2", "#16BDCA", "#9061F9"],
            labels: labels,
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return parseFloat(val).toFixed(2) + "%";
              }
            },
            legend: {
              position: 'right', 
              horizontalAlign: 'center',
              offsetX: 40,
              offsetY: 10,
              formatter: function(seriesName) {
                return seriesName;
              }
            },
            plotOptions: {
              pie: {
                labels: {
                  show: true,
                },
                dataLabels: {
                  offset: -40
                },
                pie: {
                  size: '65%',
                }
              },
            },

          };
        })
        .catch(() => {

        });
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  }
};
</script>
