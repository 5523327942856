import API from '../plugins/http';
const API_URL = 'dynamic-so/';
const dynamicSO = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    ErrorMessage: '',
    SuccessMessage: '',
  },
  actions: {
    async fetchAllDynamicSO({ commit }, props) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await API.get(API_URL, { params: props });
        commit('setLoading', false);
        commit('setItems', response.data?.data?.rows);
        commit('setRows', response.data?.data?.count);
      } catch (error) {
        commit('setError', true);
        const response = error.response;
        if (response?.status == 422) {
          commit('setErrorMessage', response?.data?.message);
        }
        commit('setLoading', false);
      }
    },
    async postResendSO({ commit }, state) {
      commit('setLoading', true);
      const payload = {
        "ticket_number":state.ticket_number
      }
      try {
        const response = await API.post(`cms/resend/so/${payload.ticket_number}`);
        commit('setLoading', false);
        return response; 
      } catch (error) {
        commit('setError', true);
        throw error; 
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.ErrorMessage = data;
    },
    setSuccessMessage(state, data) {
      state.SuccessMessage = data;
    },
  },
};

export default dynamicSO;
