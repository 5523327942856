import Vue from 'vue';
import VueRouter from 'vue-router';
const Full = () => import('../views/Full.vue');
const Notfound = () => import('../components/Page404.vue');
import Dashboard from '../views/Home.vue';
const Login = () => import('../components/auth/login.vue');
const twoFactor = () => import('../components/auth/twoFactor.vue');
const newAuth = () => import('../components/auth/newAuth.vue');
const forgotPassword = () => import('../components/auth/forgotPassword.vue');
const resetPassword = () => import('../components/auth/resetPassword.vue');
const Orders = () => import('../views/orders/order.vue');
const OrdersDetail = () => import('../views/orders/order-detail.vue');
const UserBulkregisters = () => import('../views/users/register.vue');
const Payments = () => import('../views/payments/payment.vue');
const Announcements = () =>
  import('../views/announcements/manageAnnouncement.vue');
// const CorporateDashboard = () =>
//   import('../views/corporate-dashboard/index.vue');

const ChangePassword = () => import('../components/auth/change-password.vue');
import Http from "../plugins/http"

//content component
const contenMenu = [
  require('./content').MagazinePath,
  require('./content').MagazineCreatePath,
  require('./content').MagazineEditPath,
  require('./content').NewspaperPath,
  require('./content').NewspaperCreatePath,
  require('./content').NewspaperEditPath,
  require('./content').BookPath,
  require('./content').BookCreatePath,
  require('./content').BookEditPath,
  require('./content').BulkCreate,
  require('./content').BulkCreateItemsPublisher,
  require('./content').BulkCreateAudio,
  require('./content').BulkUpdate,
  require('./content').BulkUpload,
  require('./content').BulkUploadAudio,
  require('./content').BulkUpdateOfferPremium,
  require('./content').AuthorPath,
  require('./content').AudiobookPath,
  require('./content').AudioCreatePath,
  require('./content').AudioEditPath,
  require('./content').AudioEditChapterPath,
  require('./content').NarratorPath,
  require('./content').NarratorCreatePath,
  require('./content').NarratorUpdatePath,
  require('./content').AuthorCreatePath,
  require('./content').AuthorUpdatePath,
  require('./content').AuthorPopularPath,
  require('./content').DetailItems,
  require('./content').AxItemPath,
  require('./content').UpdateAxItemPath,
  require('./content').CreateAxItemPath,
  require('./content').PremiumAxPath,
  require('./content').UpdatePremiumAxPath,
  require('./content').CreatePremiumAxPath,
  require('./content').CategoryPath,
  require('./content').EditCategoryPath,
  require('./content').AddCategoryPath,
  require('./content').EditCoverItemPath,
  require('./content').EditPreviewItemPath,
  require('./content').brandItemAx,
  require('./content').createBrandItemAx,
  require('./content').updateBrandItemAx,
  require('./content').UploadSingleAx,
  require('./content').UploadPremiumAx,
  require('./content').UploadBrandAx,
  require('./content').HomepageRecommedationPath,
  require('./content').HomepageRecommedationIdPath,
  require('./content').ContentRecomendationEditPath
];

const LayoutMenu = [
  require('./layouts').LayoutsPath,
  require('./layouts').LayoutsDetailPath,
  require('./layouts').LayoutsCreatePath,
];

const AccountMenu = [
  require('./account').UserEbookManagePath,
  require('./account').UserEbookCreatePath,
  require('./account').UserEbookEditPath,
  require('./account').RoleManagePath,
  require('./account').RoleCreatePath,
  require('./account').RoleEditPath,
  require('./account').PermissionManagePath,
  require('./account').PermissionCreatePath,
  require('./account').PermissionEditPath,
  require('./account').PublisherManagePath,
  require('./account').PublisherCreatePath,
  require('./account').PublisherUpdatePath,
  require('./account').CustomerSupportManagePath,
  require('./account').PublisherEmailPath,
  require('./account').PublisherEmailCreatePath,
  require('./account').PublisherEmailUpdatePath,
  require('./account').CustomerSupportCreatePath,
  require('./account').CompanyManagePath,
  require('./account').CompanyCreatePath,
];

const SubledgerMenu = [
  require('./subledger').SubledgerPath,
  require('./subledger').SubledgerVendorPath,
  require('./subledger').SubledgerReadingDurationsPath,
  require('./subledger').SubledgerDetailPath,
  require('./subledger').LogSubledgerPath,
];
const FinanceReportMenu = [
  require('./report').FinanceReportPath,
  require('./report').FinanceReportDetailPath,
  require('./report').ReportSingleSalesPath,
  require('./report').ReportBuffetsPath,
  require('./report').ReportBuffetsSummaryPath,
  require('./report').ReportSingleSummaryPath,
  require('./report').ReportSalesPath,
  require('./report').SalesSubscriptionByvendorPath,
  require('./report').ReportSubscriptionSummaryPath,
  require('./report').ReportSalesByPaymentPath,
  require('./report').ReportBundleSummaryPath,
  require('./report').DiscountUsagePath,
  require('./report').ReportCampaignPath,
  require('./report').ReportContentPath,
  require('./report').ReportInvoicePath,
  require('./report').ReportInvoiceSinglePath,
  require('./report').ReportInvoiceSingleDetailPath,
  require('./report').PayloadSo365Path,
  require('./report').PayloadPo365Path,
];
const MonitoringMenu = [
  require('./monitoring').MonitoringSoPath,
  require('./monitoring').MonitoringItemUploadPath,
  require('./monitoring').MonitoringItemUploadAudioPath,
  require('./monitoring').MonitoringRegisterItemAxPath,
  require('./monitoring').CatalogOfsPath,
  require('./monitoring').DetailCatalogOfsPath,
  require('./monitoring').MonitoringDraftRegisterItemAxPath,
  require('./monitoring').PayloadSo365Path,
  require('./monitoring').PayloadPo365Path,
  require('./monitoring').MonitoringLog,
  require('./monitoring').DetailMonitoringLog,
];

const CustomersMenu = [
  require('./customers').CustomersPath,
  require('./customers').CustomerDetailPath,
  require('./customers').CustomersBannedPath,
  require('./customers').CustomersBannedNew,
  require('./customers').userRenewal,
  require('./customers').userReview,
];

const MarketingMenu = [
  require('./marketing').SinglePath,
  require('./marketing').SingleCreatePath,
  require('./marketing').SingleEditPath,
  require('./marketing').SubscriptionPath,
  require('./marketing').SubscriptionCreatePath,
  require('./marketing').SubscriptionEditPath,
  require('./marketing').SubscriptionDetailPath,
  require('./marketing').BundlePath,
  require('./marketing').BundleCreatePath,
  require('./marketing').BundleEditPath,
  require('./marketing').Premium,
  require('./marketing').PremiumDetail,
  require('./marketing').PremiumItemPath,
  require('./marketing').PremiumCreatePath,
  require('./marketing').PremiumEditPath,
  require('./marketing').AndroidTierManagePath,
  require('./marketing').AndroidTierCreatePath,
  require('./marketing').AndroidTierEditPath,
  require('./marketing').IosTierManagePath,
  require('./marketing').IosTierCreatePath,
  require('./marketing').IosTierEditPath,
  require('./marketing').InvalidTiercodeOfferPath,
  require('./marketing').DiscountPath,
  require('./marketing').CreateDiscountPath,
  require('./marketing').CampaignPath,
  require('./marketing').CreateCampaignPath,
  require('./marketing').DiscountCodePath,
  require('./marketing').CreatediscountCodePath,
  require('./marketing').DiscountEditPath,
  require('./marketing').DiscountDetailPath,
  require('./marketing').DiscountSpecified,
  require('./marketing').DiscountLogManagePath,
  require('./marketing').DiscountLogDetailPath,
  require('./marketing').BannersPath,
  require('./marketing').BannersAddPath,
  require('./marketing').BannersEditPath,
  require('./marketing').DisplayCurrencyQuotes,
  require('./marketing').CreateSingleCurrencyQuotes,
  require('./marketing').EditSingleCurrencyQuotes,
];

const ClientMenu = [
  require('./clients').Vendor,
  require('./clients').EditVendor,
  require('./clients').CreateVendor,
  require('./clients').DetailVendor,
  require('./clients').VendorAggrement,
  require('./clients').VendorAggrementAdd,
  require('./clients').VendorAggrementEdit,
  require('./clients').Brands,
  require('./clients').BrandsProduct,
  require('./clients').CreateBrandPath,
  require('./clients').EditBrandPath,
  require('./clients').DisplayCategoryBrand,
  require('./clients').DisplayPremiumBrand,
  require('./clients').CreateCategoryPath,
  require('./clients').CreatePremiumPath,
  require('./clients').revenueShare,
  require('./clients').revenueShareEdit,
  require('./clients').revenueShareCreate,
  require('./clients').client,
  require('./clients').clientCreate,
  require('./clients').clientEdit,
];

const ComplaintsMenu = [
  require('./complaints').ComplaintCreditCard,
  require('./complaints').ComplaintGoogle,
  require('./complaints').ComplaintGopay,
  require('./complaints').ComplaintItunes,
  require('./complaints').ComplaintVA,
];

const PaymentsMenu = [
  require('./payments').Payment,
  require('./payments').CreatePayment,
  require('./payments').EditPayment,
];

const AnnouncementsMenu = [
  require('./announcements').AnnouncementManagePath,
  require('./announcements').AnnouncementCreatePath,
  require('./announcements').AnnouncementEditPath,
];
const ProfileMenu = [
  require('./profile').ProfileSetting,
  require('./profile').UpdateAuth,
];

const OrganizationsMenu = [
  require('./organization').Organization,
  require('./organization').createOrganization,
  require('./organization').EditOrganization,
];

const CategoryBisacMenu = [
  require('./categoryBisac').CategoryBisac,
  require('./categoryBisac').CreateCategoryBisac,
  require('./categoryBisac').EditCategoryBisac,
];

const SettingVendor = [
  require('./settings').UserListPath,
  require('./settings').AddUserVendorPath,
  require('./settings').EditUserVendorPath,
  require('./settings').VendorReportFormat,
];

const SettingMenus = [
  require('./settings').SidebarMenuListPath,
  require('./settings').SidebarMenuUpdatePath,
  require('./settings').FinanceReport,
];

const UserAdmin = [
  require('./admin').userAdmin,
  require('./admin').createUserAdmin,
  require('./admin').editUserAdmin,
  require('./admin').displayUserAdmin,
];

const Refund = [require('./refund').RefundGooglePurchase];
const AppStore = [
  require('./appstore').AppStoreNotification,
  require('./appstore').AppStoreNotificationDetail,
];
const Microsoft365 = [
  require('./microsoft365').registerItemAx,
  require('./microsoft365').updateRegisterItemAx,
  require('./microsoft365').dynamicPo,
  require('./microsoft365').dynamicSo,
  require('./microsoft365').vendorPremium,
  require('./microsoft365').ListVendorPremium,
];

const toastWelcome = () => {
  localStorage.getItem('showWelcomeToast')
  setTimeout(()=> {
    localStorage.removeItem('showWelcomeToast')
  },3500)
}
Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/dashboard',
    component: Full,
    children: [
      ...contenMenu,
      ...LayoutMenu,
      ...AccountMenu,
      ...SubledgerMenu,
      ...FinanceReportMenu,
      ...MonitoringMenu,
      ...CustomersMenu,
      ...MarketingMenu,
      ...ClientMenu,
      ...ComplaintsMenu,
      ...PaymentsMenu,
      ...AnnouncementsMenu,
      ...ProfileMenu,
      ...OrganizationsMenu,
      ...CategoryBisacMenu,
      ...SettingVendor,
      ...SettingMenus,
      ...UserAdmin,
      ...Refund,
      ...AppStore,
      ...Microsoft365,
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: '/orders',
        name: 'orders',
        component: Orders,
      },
      {
        path: '/order/detail',
        name: 'orders-detail',
        component: OrdersDetail,
      },
      {
        path: '/user-register-bulk',
        name: 'user-register-bulk',
        component: UserBulkregisters,
      },
      {
        path: '/setting/payments',
        name: 'payments',
        component: Payments,
      },
      {
        path: '/setting/announcements',
        name: 'announcements',
        component: Announcements,
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/twoFactor/:token',
    name: 'twoFactor',
    component: twoFactor,
  },
  {
    path: '/newAuth/:token',
    name: 'newAuth',
    component: newAuth,
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: forgotPassword,
  },
  {
    path: '/resetPassword/:data',
    name: 'resetPassword',
    component: resetPassword,
  },
  // {
  //   path: '/corporate-dashboard',
  //   name: 'corporate-dashboard',
  //   component: CorporateDashboard,
  // },
  {
    path: '/change-password/:token',
    name: 'change-password',
    component: ChangePassword,
  },
  { path: '*', name: 'Notfound', component: Notfound },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: 'active',
});
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('session_token');
  const roleID = JSON.parse(localStorage.getItem('session_auth')); // Ambil role ID dari localStorage
   if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!token || token === null) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      Http.get('/menu').then(response => {
        let isAllowed = false;
        const nav = response.data.data.menu;
        for (const item of nav) {
          if (item.children && Array.isArray(item.children)) {
            for (const child of item.children) {
              if (to.path.startsWith(child.url)) {
                isAllowed = true;
                toastWelcome()
                break;
              }
            }
          }
          if (roleID.role_id !== 5  ) {
            if (to.path.startsWith(item.url)) {
              isAllowed = true;
              break;
            }
          }
        }    
        if(to.path.startsWith('/items') || to.path.startsWith('/offers') || to.path.startsWith('/dashboard')) {
          isAllowed = true;
          toastWelcome()
          next();
        }
        if (roleID.role_id === 5 && isAllowed) {
          toastWelcome()
          next();
        }
        if (!isAllowed) {
          next({ path: '/not-found' });
        } else {
          next(); 
          toastWelcome()
        }
      })
      .catch(error => {
        console.log(error);
        next();
      });
    }
  } else if (to.matched.some((record) => record.meta.ebookGroup)) {
    let auth = JSON.parse(localStorage.getItem('session_auth'));
    let ebooks = auth.group == 'ebook' ? true : false;
    if (ebooks) {
      next({
        path: '/',
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else {
    let auth = JSON.parse(localStorage.getItem('session_auth'));
    if (auth) {
      let ebooks = auth.group == 'ebook' ? true : false;
      if (ebooks) {
        next({
          path: to.query.redirect,
        });
      } else {
        next();
      }
    } else {
      next();
    }
  }
  next();
});

export default router;
